import React, {useEffect} from 'react'
import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import ColumnHero from '../../components/columnHero/ColumnHero'
import Navbar2 from '../../components/navbar2/navbar2'
import TopComponent from '../../components/topComponent/TopComponent'
import MusicGalleryFinal from '../../components/musicGalleryFinal/MusicGalleryFinal'
import { useSelector } from 'react-redux'


const MusicGalleryPage = () => {


  const  navStyles={ backgroundColor: "#fff", position: "relative"}


  const images = useSelector((state) => state.website.data.musicGalleryImgs);

  useEffect(() => {
    console.log(images)

  }, [images])


  return (
    <div className="homeContainer">
   
   {/*<Title/> */}
   <TopComponent/>
    <Navbar2 styles2={navStyles}/>
    { images &&

<MusicGalleryFinal images={images}/>


    }
  
    



<ColumnHero/>

<DisclaimerRow/>

    
    </div>
  )
}

export default MusicGalleryPage