import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classes from "./adminWebsite.module.css"
import { request } from '../../../util/fetchAPI'
import ClipLoader from "react-spinners/ClipLoader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";


const AdminWebsite = () => {


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const updateViewportWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', updateViewportWidth);

        // Cleanup: Remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, []); // Empty dependency array means the effect runs once after the initial render

    useEffect(() => {
        // This effect will run whenever the viewport width changes
        console.log('Viewport width changed:', viewportWidth);

        // You can add additional logic or actions here based on viewport changes

    }, [viewportWidth]); // Dependency array with viewportWidth


    const dispatch = useDispatch();


    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "red",


    };




    //AUTHENTICATION

    const { token, user } = useSelector((state) => state.auth)
    const reduxData = useSelector((state) => state.website);



    //SETTING WHICH WEBSITE PANEL IS OPEN

    const [open, setOpen] = useState("");



    const handleOpen = (string) => {
        if (open === string) {
            setOpen("");
        } else {
            setOpen(string);


            setTimeout(() => {
                const element = document.getElementById(string);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 350);
        }
    };




    // INITIALISING STATE AND FETCHING WEBSITE



    const [website, setWebsite] = useState([]);
    const [state, setState] = useState([]);


    const fetchWebsite = async () => {
        const data = await request(`/website/getAll`, 'GET');
        setWebsite(data);
        console.log(data);

    };


    useEffect(() => {

        fetchWebsite();

    }, [])




    useEffect(() => {


        if (website.length > 0) {
            console.log(website[0].ethosTitle)


            setState({
                ...website[0],
            });


            setPhotos(website[0]?.homeImgs);
            setPhotos2(website[0]?.galleryImgs)
            setPhotos3(website[0]?.musicGalleryImgs)


        }



    }, [website])




    //HANDLING TEXTAREA RESIZE!!!



    const refs = useRef({});


    const autoExpand = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };




    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })




        autoExpand(refs.current[name]);


    }






    // HANDLING AN UPDATE


    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);
    const [photos3, setPhotos3] = useState([]);




    const [photo, setPhoto] = useState("");
    const [photo2, setPhoto2] = useState("");
    const [photo3, setPhoto3] = useState("");
    const [photo4, setPhoto4] = useState("");
    const [photo5, setPhoto5] = useState("");
    const [photo6, setPhoto6] = useState("");
    const [photo7, setPhoto7] = useState("");
    const [photo8, setPhoto8] = useState("");
    const [photo9, setPhoto9] = useState("");
    const [photo10, setPhoto10] = useState("");
    const [photo11, setPhoto11] = useState("");
    const [photo12, setPhoto12] = useState("");
    const [photo13, setPhoto13] = useState("");
    const [photo14, setPhoto14] = useState("");


    const [photo15, setPhoto15] = useState("");
    const [photo16, setPhoto16] = useState("");
    const [photo17, setPhoto17] = useState("");
    const [photo18, setPhoto18] = useState("");
    const [photo19, setPhoto19] = useState("");
    const [photo20, setPhoto20] = useState("");
    const [photoOg, setPhotoOg] = useState("")




    const [topVideo, setTopVideo] = useState(null)



    const handleVideoChange = (e) => {
        setTopVideo(e.target.files[0]);
    };


    const [extraLogo1Photo, setExtraLogo1Photo] = useState("")
    const [extraLogo2Photo, setExtraLogo2Photo] = useState("")







    const handleWebsite = async (e) => {
        e.preventDefault();
        setPreloader(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });





        let initialPhotos = website[0].galleryImgs;
     

        // Current array of photos (new photos might have been added)


        // Function to determine if a photo is new (added to the array)
        function isPhotoEdited(photo, initialPhotos) {
            return !initialPhotos.includes(photo);
        }

        // Separate photos into edited (new) and remaining (existing), while keeping track of their original indices
        let oldPhotos = [...photos2]; // Create a copy of photos2
        let editOldPhotos = [];
        let editOldPhotosIndices = [];
        let remainingOldPhotos = [];
        let remainingOldPhotosIndices = [];

        oldPhotos.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos)) {
                editOldPhotos.push(photo);
                editOldPhotosIndices.push(index);
            } else {
                remainingOldPhotos.push(photo);
                remainingOldPhotosIndices.push(index);
            }
        });

        let processedPhotos2 = [];

        if (editOldPhotos.length > 0) {
            const uploadPromises = editOldPhotos.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos2.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos2) {
                editOldPhotos[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos = new Array(oldPhotos.length);

        // Place edited photos back into their original positions
        editOldPhotos.forEach((photo, i) => {
            combinedPhotos[editOldPhotosIndices[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos.forEach((photo, i) => {
            combinedPhotos[remainingOldPhotosIndices[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos2 = combinedPhotos;




        let initialPhotos2 = website[0].musicGalleryImgs;


        function isPhotoEdited2(photo, initialPhotos2) {
            return !initialPhotos2.includes(photo);
        }

       

        // Separate photos into edited (new) and remaining (existing), while keeping track of their original indices
        let oldPhotos2 = [...photos3]; // Create a copy of photos2
        let editOldPhotos2 = [];
        let editOldPhotosIndices2 = [];
        let remainingOldPhotos2  = [];
        let remainingOldPhotosIndices2 = [];

        oldPhotos2.forEach((photo, index) => {
            if (isPhotoEdited2(photo, initialPhotos2)) {
                editOldPhotos2.push(photo);
                editOldPhotosIndices2.push(index);
            } else {
                remainingOldPhotos2.push(photo);
                remainingOldPhotosIndices2.push(index);
            }
        });

        let processedPhotos3= [];

        if (editOldPhotos2.length > 0) {
            const uploadPromises = editOldPhotos2.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos3.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos3) {
                editOldPhotos2[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos3 = new Array(oldPhotos2.length);

        // Place edited photos back into their original positions
        editOldPhotos2.forEach((photo, i) => {
            combinedPhotos3[editOldPhotosIndices2[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos2.forEach((photo, i) => {
            combinedPhotos3[remainingOldPhotosIndices2[i]] = photo;
        });

        // Assign the combined photos back to photos2
       

        let finalPhotos3 = combinedPhotos3;

        console.log(photos2); // Output the final array of photos



        let filename = null;
        let filename2 = null;
        let filename3 = null;
        let filename4 = null;
        let filename5 = null;
        let filename6 = null;
        let filename7 = null;
        let filename8 = null;
        let filename9 = null;
        let filename10 = null;
        let filename11 = null;
        let filename12 = null;
        let filename13 = null;
        let filename14 = null;



        let filename15 = null;
        let filename16 = null;
        let filename17 = null;
        let filename18 = null;
        let filename19 = null;
        let filename20 = null;
        let extraFilename1 = null;
        let extraFilename2 = null;
        let filenameOg = null;







        let videoFilename = null


        if (topVideo) {
            const formData = new FormData();

            // Generate a unique filename for the video
            videoFilename = crypto.randomUUID() + topVideo.name;

            // Append the filename and video file to the FormData object
            formData.append("filename", videoFilename);
            formData.append("video", topVideo);

            // Make a POST request to the video upload endpoint
            await request('/upload/video', "POST", {}, formData, true);
        } else {

            videoFilename = state.heroVideo

        }




        if (extraLogo1Photo) {

            const formData = new FormData()




            extraFilename1 = crypto.randomUUID() + extraLogo1Photo.name
            formData.append("filename", extraFilename1)
            formData.append("image", extraLogo1Photo)

            await request('/upload/image', "POST", {}, formData, true)

        } else {

            extraFilename1 = state.extraLogo1

        }



        if (extraLogo2Photo) {

            const formData = new FormData()




            extraFilename2 = crypto.randomUUID() + extraLogo2Photo.name
            formData.append("filename", extraFilename2)
            formData.append("image", extraLogo2Photo)

            await request('/upload/image', "POST", {}, formData, true)

        } else {
            extraFilename2 = state.extraLogo2

        }





        if (photo) {
            const formData = new FormData()




            filename = crypto.randomUUID() + photo.name
            formData.append("filename", filename)
            formData.append("image", photo)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename = state.logoImg
        }


        if (photoOg) {
            const formData = new FormData()




            filenameOg = crypto.randomUUID() + photoOg.name
            formData.append("filename", filenameOg)
            formData.append("image", photoOg)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filenameOg = state.ogImg
        }






        if (photo2) {
            const formData = new FormData()




            filename2 = crypto.randomUUID() + photo2.name
            formData.append("filename", filename2)
            formData.append("image", photo2)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename2 = state.landingImg1
        }


        if (photo3) {
            const formData = new FormData()




            filename3 = crypto.randomUUID() + photo3.name
            formData.append("filename", filename3)
            formData.append("image", photo3)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename3 = state.landingImg2
        }

        if (photo4) {
            const formData = new FormData()




            filename4 = crypto.randomUUID() + photo4.name
            formData.append("filename", filename4)
            formData.append("image", photo4)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename4 = state.landingImg3
        }



        if (photo5) {
            const formData = new FormData()




            filename5 = crypto.randomUUID() + photo5.name
            formData.append("filename", filename5)
            formData.append("image", photo5)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename5 = state.landingImg4
        }



        if (photo6) {
            const formData = new FormData()




            filename6 = crypto.randomUUID() + photo6.name
            formData.append("filename", filename6)
            formData.append("image", photo6)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename6 = state.landingImg5
        }



        if (photo7) {
            const formData = new FormData()




            filename7 = crypto.randomUUID() + photo7.name
            formData.append("filename", filename7)
            formData.append("image", photo7)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename7 = state.careerTopImg
        }



        if (photo8) {
            const formData = new FormData()




            filename8 = crypto.randomUUID() + photo8.name
            formData.append("filename", filename8)
            formData.append("image", photo8)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename8 = state.careerImg1
        }

        if (photo9) {
            const formData = new FormData()




            filename9 = crypto.randomUUID() + photo9.name
            formData.append("filename", filename9)
            formData.append("image", photo9)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename9 = state.careerImg2
        }

        if (photo10) {
            const formData = new FormData()




            filename10 = crypto.randomUUID() + photo10.name
            formData.append("filename", filename10)
            formData.append("image", photo10)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename10 = state.careerImg3
        }

        if (photo11) {
            const formData = new FormData()




            filename11 = crypto.randomUUID() + photo11.name
            formData.append("filename", filename11)
            formData.append("image", photo11)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename11 = state.chefsTableTopImg
        }

        if (photo12) {
            const formData = new FormData()




            filename11 = crypto.randomUUID() + photo12.name
            formData.append("filename", filename12)
            formData.append("image", photo12)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename12 = state.chefsTableImg1
        }


        if (photo13) {
            const formData = new FormData()




            filename13 = crypto.randomUUID() + photo13.name
            formData.append("filename", filename13)
            formData.append("image", photo13)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename13 = state.chefsTableImg2
        }

        if (photo14) {
            const formData = new FormData()




            filename14 = crypto.randomUUID() + photo14.name
            formData.append("filename", filename14)
            formData.append("image", photo14)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename14 = state.chefsTableImg3
        }

        if (photo15) {
            const formData = new FormData()




            filename15 = crypto.randomUUID() + photo15.name
            formData.append("filename", filename15)
            formData.append("image", photo15)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename15 = state.wineCaveTopImg
        }

        if (photo16) {
            const formData = new FormData()




            filename16 = crypto.randomUUID() + photo16.name
            formData.append("filename", filename16)
            formData.append("image", photo16)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename16 = state.wineCaveImg1
        }


        if (photo17) {
            const formData = new FormData()




            filename17 = crypto.randomUUID() + photo17.name
            formData.append("filename", filename17)
            formData.append("image", photo17)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename17 = state.wineCaveImg2
        }


        if (photo18) {
            const formData = new FormData()




            filename18 = crypto.randomUUID() + photo18.name
            formData.append("filename", filename18)
            formData.append("image", photo18)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename18 = state.wineCaveImg3
        }

        if (photo19) {
            const formData = new FormData()




            filename19 = crypto.randomUUID() + photo19.name
            formData.append("filename", filename19)
            formData.append("image", photo19)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename19 = state.menuTopImg
        }



        if (photo20) {
            const formData = new FormData()




            filename20 = crypto.randomUUID() + photo20.name
            formData.append("filename", filename20)
            formData.append("image", photo20)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename20 = state.musicTopImg
        }
























        try {


            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;

            data = await request(`/website/${website[0]?._id}`, 'PUT', options, {
                ...state,


                galleryImgs: [...finalPhotos2],
                musicGalleryImgs: [...finalPhotos3],

                logoImg: filename,
                heroVideo: videoFilename,

                landingImg1: filename2,
                landingImg2: filename3,
                landingImg3: filename4,
                landingImg4: filename5,
                landingImg5: filename6,

                extraLogo1: extraFilename1,
                extraLogo2: extraFilename2,


                careerTopImg: filename7,
                careerImg1: filename8,
                careerImg2: filename9,
                careerImg3: filename10,

                chefsTableTopImg: filename11,
                chefsTableImg1: filename12,
                chefsTableImg2: filename13,
                chefsTableImg3: filename14,
                ogImg: filenameOg,
                wineCaveTopImg: filename15,
                wineCaveImg1: filename16,
                wineCaveImg2: filename17,
                wineCaveImg3: filename18,
                menuTopImg: filename19,
                musicTopImg: filename20,




            })







            fetchWebsite();
            setOpen("")
            setPreloader(false)
            alert("The website has been successfully updated!")
            window.scrollTo({ top: 0, behavior: 'smooth' });




        } catch (error) {
            console.error(error)

            setPreloader(false)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }






    const handleUploadPhotoEdit2 = (e) => {
        const files = Array.from(e.target.files);

        setPhotos2([...files, ...photos2]);


    };

    
    const handleUploadPhotoEdit3 = (e) => {
        const files = Array.from(e.target.files);

        setPhotos3([...files, ...photos3]);


    };



    const deletePhoto2 = (photo) => {


        setPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };


    const deletePhoto3 = (photo) => {


        setPhotos3((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };









    const [preloader, setPreloader] = useState(false)



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos2);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos2(updatedItems);
    };

    const onDragEnd2 = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos3);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos3(updatedItems);
    };









    return (
        <div className={classes.listPropertyWrapper} style={open === "" ? { width: viewportWidth < 600 ? "65%" : "40%" } : { width: viewportWidth < 600 ? "70%" : "50%" }} onClick={(e) => e.stopPropagation()}>
            <h2>EDIT WEBSITE</h2>

            {preloader ? (
                <div style={{ width: "auto" }}>
                    <ClipLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) :

                (


                    <form onSubmit={handleWebsite}>


                        <h3 className={classes.scripTitle} id="home" onClick={() => { handleOpen("home") }}>HOME</h3>


                        <div className={classes.openWrapper} style={open === "home" ? { height: "auto", opacity: "1", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>


                            <div className={classes.inputs}>
                                <label>
                                    Website Title:
                                    <input type="text" className={classes.titleInput2} value={state.landingHeroTitle} name='landingHeroTitle' onChange={handleState} />
                                </label>
                                <label>
                                    Landing Desc Title 1:
                                    <input type="text" className={classes.titleInput2} value={state.landingTitle1} name='landingTitle1' onChange={handleState} />
                                </label>
                                <label>
                                    Landing Desc Title 2:
                                    <input type="text" className={classes.titleInput2} value={state.landingTitle2} name='landingTitle2' onChange={handleState} />
                                </label>
                                <label>
                                    Landing Desc Title 3:
                                    <input type="text" className={classes.titleInput2} value={state.landingTitle3} name='landingTitle3' onChange={handleState} />
                                </label>
                                <label>
                                    Landing Desc Title 4:
                                    <input type="text" className={classes.titleInput2} value={state.landingTitle4} name='landingTitle4' onChange={handleState} />
                                </label>
                                <label>
                                    Landing Desc Title 5:
                                    <input type="text" className={classes.titleInput2} value={state.landingTitle5} name='landingTitle5' onChange={handleState} />
                                </label>

                                <label>
                                    Landing Desc Title 6:
                                    <input type="text" className={classes.titleInput2} value={state.landingTitle6} name='landingTitle6' onChange={handleState} />
                                </label>

                                <label>
                                    Landing Hashtag 1:
                                    <input type="text" className={classes.titleInput2} value={state.landingHastag1} name='landingHastag1' onChange={handleState} />
                                </label>

                                <label>
                                    Landing Hashtag 2:
                                    <input type="text" className={classes.titleInput2} value={state.landingHastag2} name='landingHastag2' onChange={handleState} />

                                </label>

                                <label>
                                    Landing Button 1:
                                    <select className={classes.titleInput2} value={state.landingButton1} name='landingButton1' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Menu">Menu</option>
                                        <option value="Gallery">Gallery</option>
                                        <option value="ContactUs">Contact Us</option>
                                        <option value="Book">Book A Table</option>
                                        <option value="Experience">Chef's Table Experience</option>
                                        <option value="Careers">Careers</option>
                                        <option value="Music">Music</option>
                                        <option value="Wine">Wine Experience</option>
                                    </select>
                                </label>

                                <label>
                                    Landing Button 2:
                                    <select className={classes.titleInput2} value={state.landingButton2} name='landingButton2' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Menu">Menu</option>
                                        <option value="Gallery">Gallery</option>
                                        <option value="Contact Us">Contact Us</option>
                                        <option value="Book">Book A Table</option>
                                        <option value="Experience">Chef's Table Experience</option>
                                        <option value="Careers">Careers</option>
                                        <option value="Music">Music</option>
                                        <option value="Wine">Wine Experience</option>
                                    </select>
                                </label>

                                <label>
                                    Landing Button 3:
                                    <select className={classes.titleInput2} value={state.landingButton3} name='landingButton3' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Menu">Menu</option>
                                        <option value="Gallery">Gallery</option>
                                        <option value="Contact Us">Contact Us</option>
                                        <option value="Book">Book A Table</option>
                                        <option value="Experience">Chef's Table Experience</option>
                                        <option value="Careers">Careers</option>
                                        <option value="Music">Music</option>
                                        <option value="Wine">Wine Experience</option>
                                    </select>
                                </label>

                                <label>
                                    Landing Button 4:
                                    <select className={classes.titleInput2} value={state.landingButton4} name='landingButton4' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Menu">Menu</option>
                                        <option value="Gallery">Gallery</option>
                                        <option value="Contact Us">Contact Us</option>
                                        <option value="Book">Book A Table</option>
                                        <option value="Experience">Chef's Table Experience</option>
                                        <option value="Careers">Careers</option>
                                        <option value="Music">Music</option>
                                        <option value="Wine">Wine Experience</option>
                                    </select>
                                </label>

                                <label>
                                    Landing Button 5:
                                    <select className={classes.titleInput2} value={state.landingButton5} name='landingButton5' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Menu">Menu</option>
                                        <option value="Gallery">Gallery</option>
                                        <option value="Contact Us">Contact Us</option>
                                        <option value="Book">Book A Table</option>
                                        <option value="Experience">Chef's Table Experience</option>
                                        <option value="Careers">Careers</option>
                                        <option value="Music">Music</option>
                                        <option value="Wine">Wine Experience</option>
                                    </select>
                                </label>



                            </div>


                            <div>

                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="videoEdit">Upload Home Video</label>
                                    <input
                                        id="videoEdit"
                                        type="file"
                                        accept="video/*" // accept only video files
                                        style={{ display: 'none' }}
                                        onChange={handleVideoChange}
                                    />

                                    {topVideo ? (
                                        <video src={URL.createObjectURL(topVideo)} style={{ width: '300px', height: '200px' }} controls />
                                    ) : (
                                        <video src={`https://connollysapi-n476.onrender.com/images/${state?.heroVideo}`} style={{ width: '300px', height: '200px' }} controls />
                                    )}


                                </div>
                            </div>

                            <div className={classes.photoSection}>



                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="photoEdit">Logo Img</label>
                                    <input
                                        id="photoEdit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto(e.target.files[0]) }}
                                    />

                                    {photo === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.logoImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="photoEditOgImg">Link Img</label>
                                    <input
                                        id="photoEditOgImg"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhotoOg(e.target.files[0]) }}
                                    />

                                    {photoOg === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.ogImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photoOg)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="extraLogo1Edit">Logo Img 2</label>
                                    <input
                                        id="extraLogo1Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setExtraLogo1Photo(e.target.files[0]) }}
                                    />

                                    {extraLogo1Photo === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.extraLogo1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(extraLogo1Photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="extraLogo2Edit">Logo Img 2</label>
                                    <input
                                        id="extraLogo2Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setExtraLogo2Photo(e.target.files[0]) }}
                                    />

                                    {extraLogo2Photo === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.extraLogo2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(extraLogo2Photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>








                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="photo2Edit">Landing Image 1</label>
                                    <input
                                        id="photo2Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto2(e.target.files[0]) }}
                                    />

                                    {photo2 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.landingImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo2)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo3Edit">Landing Image 2</label>
                                    <input
                                        id="photo3Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto3(e.target.files[0]) }}
                                    />

                                    {photo3 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.landingImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo3)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo4Edit">Landing Image 3</label>
                                    <input
                                        id="photo4Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto4(e.target.files[0]) }}
                                    />

                                    {photo4 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.landingImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo4)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo5Edit">Landing Image 4</label>
                                    <input
                                        id="photo5Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto5(e.target.files[0]) }}
                                    />

                                    {photo5 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.landingImg4}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo5)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo6Edit">Landing Image 5</label>
                                    <input
                                        id="photo6Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto6(e.target.files[0]) }}
                                    />

                                    {photo6 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.landingImg5}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo6)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>







                            <h6>Landing Desc 1</h6>
                            <textarea className={classes.scrip} type="text" value={state.landingDesc1} name='landingDesc1' ref={(el) => (refs.current['landingDesc1'] = el)} onChange={handleState} />
                            <h6>Landing Desc 2</h6>
                            <textarea className={classes.scrip} type="text" value={state.landingDesc2} name='landingDesc2' ref={(el) => (refs.current['landingDesc2'] = el)} onChange={handleState} />
                            <h6>Landing Desc 3</h6>
                            <textarea className={classes.scrip} type="text" value={state.landingDesc3} name='landingDesc3' ref={(el) => (refs.current['landingDesc3'] = el)} onChange={handleState} />
                            <h6>Landing Desc 4</h6>
                            <textarea className={classes.scrip} type="text" value={state.landingDesc4} name='landingDesc4' ref={(el) => (refs.current['landingDesc4'] = el)} onChange={handleState} />
                            <h6>Landing Desc 5</h6>
                            <textarea className={classes.scrip} type="text" value={state.landingDesc5} name='landingDesc5' ref={(el) => (refs.current['landingDesc5'] = el)} onChange={handleState} />



                            <h6>Disclaimer</h6>
                            <textarea className={classes.scrip} type="text" value={state.disclaimer} name='disclaimer' onChange={handleState} />


                            <button className={classes.formButton2} type="submit">Update Home Page</button>

                        </div>


                        <h3 className={classes.scripTitle} id="portfolio" onClick={() => { handleOpen("portfolio") }}>MENUS</h3>

                        <div className={classes.openWrapper} style={open === "portfolio" ? { height: "auto", opacity: "1", maxHeight: "2000px", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>

                            <div className={classes.inputs}>

                                <label>
                                    Menu Title:
                                    <input type="text" className={classes.titleInput2} value={state.menuTitle} name='menuTitle' onChange={handleState} />
                                </label>

                                <label>
                                    Menu Desc Title:
                                    <input type="text" className={classes.titleInput2} value={state.menuDescTitle} name='menuDescTitle' onChange={handleState} />
                                </label>

                                <label>
                                    Menu Message:
                                    <input type="text" className={classes.titleInput2} value={state.menuMessage} name='menuMessage' onChange={handleState} />

                                </label>



                            </div>

                            <div className={classes.photoSection}>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo19Edit">Menu Top Image:</label>

                                    <input
                                        id="photo19Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto19(e.target.files[0]) }}
                                    />

                                    {photo19 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.menuTopImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo19)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>

                            <h6>Menu Description:</h6>
                            <textarea className={classes.scrip} type="text" value={state.menuDesc} name='menuDesc' ref={(el) => (refs.current['menuDesc'] = el)} onChange={handleState} />


                            <button className={classes.formButton2} type="submit">Update Menus</button>

                        </div>


                        <h3 className={classes.scripTitle} id="about" onClick={() => { handleOpen("about") }}>MUSIC @ CONNOLLY'S</h3>


                        <div className={classes.openWrapper} style={open === "about" ? { height: "auto", maxHeight: "2000px", opacity: "1", } : { maxHeight: "0", opacity: "0" }}>


                            <div className={classes.inputs}>

                                <label>
                                    Music Title:
                                    <input type="text" className={classes.titleInput2} value={state.musicTitle} name='musicTitle' onChange={handleState} />

                                </label>

                                <label>
                                    Music Desc Title:
                                    <input type="text" className={classes.titleInput2} value={state.musicDescTitle} name='musicDescTitle' onChange={handleState} />

                                </label>

                                <label>
                                    Music Desc Title 2:
                                    <input type="text" className={classes.titleInput2} value={state.musicDescTitle2} name='musicDescTitle2' onChange={handleState} />

                                </label>

                            </div>


                            <div className={classes.photoSection}>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo20Edit">Music Top Image:</label>

                                    <input
                                        id="photo20Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto20(e.target.files[0]) }}
                                    />

                                    {photo20 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.musicTopImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo20)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                            </div>

                            <h6>Music Description:</h6>
                            <textarea className={classes.scrip} type="text" value={state.musicDesc} name='musicDesc' ref={(el) => (refs.current['musicDesc'] = el)} onChange={handleState} />



                            <button className={classes.formButton2} type="submit">Update Music</button>

                        </div>





                        <h3 className={classes.scripTitle} id="do" onClick={() => { handleOpen("do") }}>BOOK A TABLE</h3>




                        <div className={classes.openWrapper} style={open === "do" ? { height: "auto", maxHeight: "2000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>


                            <div className={classes.inputs}>
                                <label>
                                    Book a Table Title:
                                    <input type="text" className={classes.titleInput2} value={state.bookTableTitle} name='bookTableTitle' onChange={handleState} />

                                </label>

                                <label>
                                    Book a Table Message:
                                    <input type="text" className={classes.titleInput2} value={state.bookTableMessage} name='bookTableMessage' onChange={handleState} />

                                </label>

                            </div>



                            <h6>Book a Table Desc</h6>

                            <textarea className={classes.scrip} type="text" value={state.bookTableDesc} name='bookTableDesc' ref={(el) => (refs.current['bookTableDesc'] = el)} onChange={handleState} />

                            <button className={classes.formButton2} type="submit">Update Book a Table</button>

                        </div>


                        <h3 className={classes.scripTitle} id="work" onClick={() => { handleOpen("work") }}>WINE EXPERIENCE ROOM</h3>


                        <div className={classes.openWrapper} style={open === "work" ? { height: "auto", maxHeight: "2000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0", }}>

                            <div className={classes.inputs}>
                                <label>
                                    Wine Cave Title:
                                    <input type="text" className={classes.titleInput2} value={state.wineCaveTitle} name='wineCaveTitle' onChange={handleState} />

                                </label>

                            </div>

                            <div className={classes.inputs}>
                                <label>
                                    Wine Cave Desc Title:
                                    <input type="text" className={classes.titleInput2} value={state.wineCaveDescTitle} name='wineCaveDescTitle' onChange={handleState} />

                                </label>

                            </div>

                            <div className={classes.photoSection}>
                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo15Edit">Wine Cave Top Image:</label>

                                    <input
                                        id="photo15Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto15(e.target.files[0]) }}
                                    />

                                    {photo15 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.wineCaveTopImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo15)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo16Edit">Wine Cave Image 1:</label>

                                    <input
                                        id="photo16Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto16(e.target.files[0]) }}
                                    />

                                    {photo16 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.wineCaveImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo16)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo17Edit">Wine Cave Image 2:</label>

                                    <input
                                        id="photo17Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto17(e.target.files[0]) }}
                                    />

                                    {photo17 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.wineCaveImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo17)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo18Edit">Wine Cave Image 3:</label>

                                    <input
                                        id="photo18Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto18(e.target.files[0]) }}
                                    />

                                    {photo18 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.wineCaveImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo18)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                            </div>



                            <h6>Wine Cave Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.wineCaveDesc} name='wineCaveDesc' ref={(el) => (refs.current['wineCaveDesc'] = el)} onChange={handleState} />
                            <button className={classes.formButton2} type="submit">Update Wine Cave</button>

                        </div>

                        <h3 className={classes.scripTitle} id="ethos" onClick={() => { handleOpen("ethos") }}>CAREERS</h3>


                        <div className={classes.openWrapper} style={open === "ethos" ? { height: "auto", maxHeight: "2000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>

                            <div className={classes.inputs}>
                                <label>
                                    Career Title:
                                    <input type="text" className={classes.titleInput2} value={state.careerTitle} name='careerTitle' onChange={handleState} />

                                </label>
                                <label>
                                    Career Description Title:
                                    <input type="text" className={classes.titleInput2} value={state.careerDescTitle} name='careerDescTitle' onChange={handleState} />

                                </label>


                            </div>


                            <div className={classes.photoSection}>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo7Edit">Career Top Image </label>
                                    <input
                                        id="photo7Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto7(e.target.files[0]) }}
                                    />

                                    {photo7 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.careerTopImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo7)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo8Edit">Career Image 1 </label>
                                    <input
                                        id="photo8Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto8(e.target.files[0]) }}
                                    />

                                    {photo8 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.careerImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo8)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo9Edit">Career Image 2 </label>
                                    <input
                                        id="photo9Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto9(e.target.files[0]) }}
                                    />

                                    {photo9 === "" ? (


                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.careerImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo9)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo10Edit">Career Image 3 </label>
                                    <input
                                        id="photo10Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto10(e.target.files[0]) }}
                                    />

                                    {photo10 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.careerImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo10)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                            </div>
                            <h6>Careers Description</h6>
                            <textarea className={classes.scrip} type="text" value={state.careerDesc} name='careerDesc' ref={(el) => (refs.current['careerDesc'] = el)} onChange={handleState} />

                            <button className={classes.formButton2} type="submit">Update Careers</button>

                        </div>


                        <h3 className={classes.scripTitle} id="case" onClick={() => { handleOpen("case") }}>CHEF'S TABLE</h3>

                        <div className={classes.openWrapper} style={open === "case" ? { height: "auto", maxHeight: "2000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>

                            <div className={classes.inputs}>
                                <label>
                                    Chefs Table Title:
                                    <input type="text" className={classes.titleInput2} value={state.chefsTableTitle} name='chefsTableTitle' onChange={handleState} />

                                </label>

                            </div>

                            <div className={classes.inputs}>
                                <label>
                                    Chefs Table Desc Title:
                                    <input type="text" className={classes.titleInput2} value={state.chefsTableDescTitle} name='chefsTableDescTitle' onChange={handleState} />

                                </label>

                            </div>


                            <div className={classes.photoSection} >

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo11Edit">Chef's Table Top Image</label>

                                    <input
                                        id="photo11Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto11(e.target.files[0]) }}
                                    />

                                    {photo11 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.chefsTableTopImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo11)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo12Edit">Chef's Table Image 1:</label>

                                    <input
                                        id="photo12Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto12(e.target.files[0]) }}
                                    />

                                    {photo12 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.chefsTableImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo12)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo13Edit">Chef's Table Image 2:</label>

                                    <input
                                        id="photo13Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto13(e.target.files[0]) }}
                                    />

                                    {photo13 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.chefsTableImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo13)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo14Edit">Chef's Table Image 3:</label>

                                    <input
                                        id="photo14Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto14(e.target.files[0]) }}
                                    />

                                    {photo14 === "" ? (
                                        <img src={`https://connollysapi-n476.onrender.com/images/${state?.chefsTableImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo14)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                            </div>

                            <h6>Chefs Table Description</h6>
                            <textarea className={classes.scrip} type="text" value={state.chefsTableDesc} name='chefsTableDesc' ref={(el) => (refs.current['chefsTableDesc'] = el)} onChange={handleState} />

                            <button className={classes.formButton2} type="submit">Update Chef's Table</button>
                        </div>


                        <h3 className={classes.scripTitle} id="contact" onClick={() => { handleOpen("contact") }}>CONTACT</h3>



                        <div className={classes.openWrapper} style={open === "contact" ? { height: "auto", maxHeight: "2000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>

                            <div className={classes.inputs}>

                                <label>
                                    Address (Main):
                                    <input type="text" className={classes.titleInput2} value={state.addressLine} name='addressLine' onChange={handleState} />

                                </label>

                                <label>
                                    Address (Secondary):
                                    <input type="text" className={classes.titleInput2} value={state.addressLine2} name='addressLine2' onChange={handleState} />

                                </label>

                                <label>
                                    Email:
                                    <input type="text" className={classes.titleInput2} value={state.contactEmail} name='contactEmail' onChange={handleState} />

                                </label>

                                <label>
                                    Number:
                                    <input type="text" className={classes.titleInput2} value={state.contactNumber} name='contactNumber' onChange={handleState} />

                                </label>

                                <label>
                                    Contact Message Title 1:
                                    <input type="text" className={classes.titleInput2} value={state.contactMessageTitle1} name='contactMessageTitle1' onChange={handleState} />
                                </label>
                                <label>
                                    Contact Message Title 2:
                                    <input type="text" className={classes.titleInput2} value={state.contactMessageTitle2} name='contactMessageTitle2' onChange={handleState} />
                                </label>

                                <label>
                                    X (Twitter) Link:
                                    <input type="text" className={classes.titleInput2} value={state.xLink} name='xLink' onChange={handleState} />
                                </label>
                                <label>
                                    Facebook Link:
                                    <input type="text" className={classes.titleInput2} value={state.facebookLink} name='facebookLink' onChange={handleState} />
                                </label>

                                <label>
                                    Instagram Link:
                                    <input type="text" className={classes.titleInput2} value={state.instaLink} name='instaLink' onChange={handleState} />
                                </label>



                            </div>


                            <h6>Contact Message 1</h6>
                            <textarea className={classes.scrip} type="text" value={state.contactMessageText1} name='contactMessageText1' ref={(el) => (refs.current['contactMessageText1'] = el)} onChange={handleState} />
                            <h6>Contact Message 2</h6>
                            <textarea className={classes.scrip} type="text" value={state.contactMessageText2} name='contactMessageText2' ref={(el) => (refs.current['contactMessageText2'] = el)} onChange={handleState} />
                            <button className={classes.formButton2} type="submit">Update Contact</button>





                        </div>



                        <h3 className={classes.scripTitle} id="gallery" onClick={() => { handleOpen("gallery") }}>GALLERY</h3>

                        <div className={classes.openWrapper} style={open === "gallery" ? { height: "auto", maxHeight: "100000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>
                            <div className={classes.photoSection}>


                                <div className={classes.upload} >
                                   
                                    <label htmlFor="photosEdit2"  className={classes.plusButtonDiv}>
                                        <IoMdAddCircle style={{ marginBottom: "8vh" }} className={classes.plusButton} />

                                    </label>
                                    <input id="photosEdit2" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit2} />

                                    <DragDropContext onDragEnd={onDragEnd} >
                                        <Droppable droppableId="panelItems">
                                            {(provided) => (
                                                <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                                    {photos2.map((photo, index) => (

                                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                            {(provided) => (

                                                                <div className={classes.keyword} ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps} key={index}>

                                                                    <AiOutlineClose onClick={() => {
                                                                        deletePhoto2(photo)
                                                                    }} className={classes.removeIcon} />
                                                                    <img
                                                                        src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://connollysapi-n476.onrender.com/images/${photo}`}
                                                                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                        alt={`Uploaded ${index + 1}`}
                                                                    />

                                                                </div>
                                                            )}



                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>



                                </div>


                            </div>


                            <button className={classes.formButton2} type="submit">Update Gallery</button>
                        </div>



                        <h3 className={classes.scripTitle} id="musicGallery" onClick={() => { handleOpen("musicGallery") }}> MUSIC GALLERY</h3>

<div className={classes.openWrapper} style={open === "musicGallery" ? { height: "auto", maxHeight: "100000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>
    <div className={classes.photoSection}>


        <div className={classes.upload} >
           
            <label htmlFor="photosEdit3"  className={classes.plusButtonDiv}>
                <IoMdAddCircle style={{ marginBottom: "8vh" }} className={classes.plusButton} />

            </label>
            <input id="photosEdit3" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit3} />

            <DragDropContext onDragEnd={onDragEnd2} >
                <Droppable droppableId="panelItems">
                    {(provided) => (
                        <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                            {photos3.map((photo, index) => (

                                <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                    {(provided) => (

                                        <div className={classes.keyword} ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} key={index}>

                                            <AiOutlineClose onClick={() => {
                                                deletePhoto3(photo)
                                            }} className={classes.removeIcon} />
                                            <img
                                                src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://connollysapi-n476.onrender.com/images/${photo}`}
                                                style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                alt={`Uploaded ${index + 1}`}
                                            />

                                        </div>
                                    )}



                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>



        </div>


    </div>


    <button className={classes.formButton2} type="submit">Update Music Gallery</button>
</div>






























                    </form>



                )


            }











        </div >
    )
}

export default AdminWebsite