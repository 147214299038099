import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import classes from "./adminMenus.module.css"
import { request } from '../../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AdminMenuEdit from '../AdminMenuEdit/AdminMenuEdit';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import { HiArrowSmUp } from "react-icons/hi";
import { HiOutlineArrowSmDown } from "react-icons/hi";

const AdminMenus = ({ currentUser }) => {

    const { token, user } = useSelector((state) => state.auth)


    const [currentWord, setCurrentWord] = useState("")
    const [currentWord2, setCurrentWord2] = useState("")
    const [currentWord3, setCurrentWord3] = useState("")
    const [currentTitle, setCurrentTitle] = useState("")
    const [currentType, setCurrentType] = useState("")
    const [currentRoute, setCurrentRoute] = useState("")

    const [menuItems, setMenuItems] = useState([])





    const handleTitle = (e) => {
        setCurrentTitle(e.target.value)
    }


    const handleAddItem = () => {


        let newItem = {}

        if (currentType !== "") {


            if (currentType === "WineItem" || currentType === "Dish") {

                newItem = {
                    elementType: currentType,
                    content: [{ contentString: currentWord }, { contentString: currentWord2 }, { contentString: currentWord3 }]
                }

            } else {

                newItem = {
                    elementType: currentType,
                    content: [{ contentString: currentWord }]
                }

            }



            setMenuItems([...menuItems, newItem]);

            setCurrentWord("");
            setCurrentWord2("");
            setCurrentWord3("")
            setCurrentType("");
        } else {
            alert("You must set an element type")
        }


    }







    const onDragEnd = (result) => {


        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(menuItems);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setMenuItems(updatedItems);
    };


    const deleteMenuItem = (index) => {
        const items = [...menuItems];
        items.splice(index, 1);
        setMenuItems(items);
    };



    const handleTypeChange = (e) => {
        setCurrentType(e.target.value);

    };


    const [DBMenus, setDBMenus] = useState([])



    const fetchDBMenus = async () => {
        const data = await request(`/menus/getAll`, 'GET');
        setDBMenus(data);
        setSelectedMenu({})
        console.log(data);

    };


    useEffect(() => {
        fetchDBMenus();

    }, [])




    const handleRoute = (e) => {
        const newRoute = e.target.value;
        const containsNumbers = /\d/.test(newRoute); // Regular expression to check for numbers
        const containsNonAlphabetic = /[^a-zA-Z]/.test(newRoute); // Regular expression to check for non-alphabetic characters

        if (!newRoute.includes(" ") && newRoute.length <= 11 && !containsNumbers && !containsNonAlphabetic) {
            // Check if the input meets the conditions
            setCurrentRoute(newRoute);
        } else {
            // Handle the case where the input does not meet the conditions
            if (newRoute.includes(" ")) {
                alert("Route cannot contain space characters");
            }
            if (newRoute.length > 11) {
                alert("Route cannot exceed 11 characters");
            }
            if (containsNumbers) {
                alert("Route cannot contain numbers");
            }
            if (containsNonAlphabetic) {
                alert("Route can only contain alphabetic characters");
            }
            // Optionally clear the route state
            // setCurrentRoute("");
        }
    }





    const handleWord = (e) => {
        setCurrentWord(e.target.value)
    }
    const handleWord2 = (e) => {
        setCurrentWord2(e.target.value)
    }
    const handleWord3 = (e) => {
        setCurrentWord3(e.target.value)
    }



    const handleCreateMenu = async (e) => {


        if (currentRoute === "") {
            alert("You must provide a URL ENDPOINT for the Menu...")
            return;
        }

        if (currentTitle === "") {
            alert("You must provide a TITLE for the Menu...")
            return;

        }

        setPreloader(true)



        try {


            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;

            data = await request(`/menus/${currentUser}`, 'POST', options, {

                title: currentTitle,
                route: currentRoute,
                contents: menuItems



            })






            setPreloader(false)
            alert("Your MENU has been added successfully!")
            setCurrentTitle("")
            setCurrentRoute("")
            setMenuItems([]);
            fetchDBMenus();


        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");
            setPreloader(false)

        }

    }



    const PF = process.env.REACT_APP_PUBLIC_FOLDER;

    const [selectedMenu, setSelectedMenu] = useState({})
    const editMenuRef = useRef(null);
    const [showForm2, setShowForm2] = useState(false)
    const [showForm, setShowForm] = useState(false)



    //TOGGLE MENU ORDER FUNCTIONS



    const handleSubmitOrderToggle = async () => {
        try {
            console.log("Starting...");

            // Making the request using your custom `request` function
            const response = await request(
                "/menus/replaceAllMenus/super",
                "POST",
                {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Ensure Content-Type header is set
                },
                { body: [...DBMenus] } // Stringify the array to ensure it's sent as JSON
            );


    
            console.log("Made it here with response:", response);


            // Check if the response is successful
            if (response) {
                alert("Your MENU ORDER has been successfully updated!");
                setMode(false)

            } else {
                alert(`Failed to change MENU ORDER: ${response.message || "Unknown error"}`);
            }
        } catch (error) {
            // Handle errors
            console.error(error);
            alert("Error updating MENU ORDER. Try signing in and signing out of the app to renew your access token.");
        }
    };


    const moveUp = (id) => {

        console.log("Running " + id)
        setDBMenus((prevMenus) => {
            const index = prevMenus.findIndex((menu) => menu._id === id);
            if (index <= 0) return prevMenus; // Prevent moving up if at the first position or not found

            // Create a new array with elements moved up
            const updatedMenus = [
                ...prevMenus.slice(0, index - 1), // Keep all elements before the target
                prevMenus[index], // Move the current element to the position of the previous one
                prevMenus[index - 1], // Swap the previous element
                ...prevMenus.slice(index + 1), // Keep all elements after the target
            ];


            console.log("sucessful")
            return updatedMenus;
        });
    };

    const moveDown = (id) => {

        console.log("Running " + id)
        setDBMenus((prevMenus) => {
            const index = prevMenus.findIndex((menu) => menu._id === id);
            if (index === -1 || index >= prevMenus.length - 1) return prevMenus; // Prevent moving down if at the last position or not found

            // Create a new array with elements moved down
            const updatedMenus = [
                ...prevMenus.slice(0, index), // Keep all elements before the target
                prevMenus[index + 1], // Swap the next element
                prevMenus[index], // Move the current element to the position of the next one
                ...prevMenus.slice(index + 2), // Keep all elements after the next one
            ];

            console.log("sucessful")

            return updatedMenus;
        });
    };













    const handleSelectedMenu = async (id) => {
        const data = await request(`/menus/find/${id}`, "GET");
        setSelectedMenu(data);





        console.log(data)


    }


    useEffect(() => {
        if (editMenuRef.current && Object.keys(selectedMenu).length !== 0) {
            editMenuRef.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, [selectedMenu])


    const handleCloseForm = () => {


        setSelectedMenu({})
    }



    const deleteMenu = async () => {
        try {
            await request(`/menus/${selectedMenu._id}/${currentUser}`, 'DELETE', { Authorization: `Bearer ${token}` });


            fetchDBMenus();
            setSelectedMenu({})
            alert("Your MENU has been successfully deleted!")

        } catch (error) {
            console.log(error)

            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };

    const confirmDelete = () => {

        deleteMenu();


        setShowForm(false);


    };






    const handleEditCurrentContentString = (index, objectIndex, e) => {

        const newMenuItems = [...menuItems];


        newMenuItems[index].content[objectIndex].contentString = e.target.value;
        setMenuItems(newMenuItems);



    }


    const [open, setOpen] = useState("")



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }



    const [showFormPreview, setShowFormPreview] = useState(false)




    const [preloader, setPreloader] = useState(false)


    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "red",


    };


    const [mode, setMode] = useState(false)


    const enterExitToggleMode = () => {
        setMode(!mode)
    }








    return (
        <div className={classes.container}>


            {preloader ? (
                <div style={{ width: "auto" }}>
                    <ClipLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (
                <div className={classes.makeWrapper}>

                    <h2>CREATE NEW MENU</h2>

                    <input
                        className={classes.titleInput}
                        type="text"
                        placeholder="Menu Title..."
                        name="title"
                        value={currentTitle || ""}
                        onChange={handleTitle}
                    />


                    <input
                        className={classes.titleInput2}
                        type="text"
                        placeholder="URL Endpoint"
                        name="title"
                        value={currentRoute || ""}
                        onChange={handleRoute}
                    />

                    <button className={classes.uniButton} onClick={() => { setShowFormPreview(true) }}>See Preview</button>

                    <div className={classes.keywordDiv}>

                        <h2>ADD MENU ITEM</h2>

                        <div className={classes.typeSelection}>
                            <label>
                                Select Type:
                                <select className={classes.titleInput3} value={currentType} onChange={handleTypeChange}>
                                    <option value="">Select an option</option> {/* Non-option */}
                                    <option value="CategoryTitle">CategoryTitle</option>
                                    <option value="Dish">Dish</option>
                                    <option value="Message">Message</option>
                                    <option value="WineItem">WineItem</option>
                                    <option value="Line">Line</option>
                                    <option value="Break">Break</option>
                                </select>
                            </label>
                        </div>


                        {
                            currentType === "WineItem" || currentType === "Dish" ?


                                (

                                    <div className={classes.contentInputs}>


                                        <input
                                            className={classes.titleInput2}
                                            type="text"
                                            placeholder="Title..."
                                            name="keyword"
                                            value={currentWord || ""}
                                            onChange={handleWord}
                                        />


                                        <input
                                            className={classes.titleInput2}
                                            type="text"
                                            placeholder="Description..."
                                            name="keyword2"
                                            value={currentWord2 || ""}
                                            onChange={handleWord2}
                                        />


                                        <input
                                            className={classes.titleInput2}
                                            type="text"
                                            placeholder="Price..."
                                            name="keyword3"
                                            value={currentWord3 || ""}
                                            onChange={handleWord3}
                                        />

                                    </div>

                                ) : currentType === "Line" || currentType === "Break" ? (
                                    <>

                                    </>



                                ) : (
                                    <div className={classes.contentInputs}>
                                        <input
                                            className={classes.titleInput2}
                                            type="text"
                                            placeholder="Text..."
                                            name="keyword"
                                            value={currentWord || ""}
                                            onChange={handleWord}
                                        />
                                    </div>
                                )
                        }






                        <div className={classes.plusButtonDiv}>
                            <IoMdAddCircle onClick={handleAddItem} className={classes.plusButton} />

                        </div>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => { setOpen("") }}>
                        <Droppable droppableId="menuItems">
                            {(provided) => (
                                <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                    {menuItems.map((item, index) => (
                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={classes.keyword}
                                                >


                                                    <AiOutlineClose onClick={() => {
                                                        deleteMenuItem(index)
                                                    }} className={classes.removeIcon} />







                                                    <p className={classes.open} onClick={() => { handleOpen(`box-${index}`) }}><span>{item.elementType}</span>{item.content[0].contentString !== "" && item.content[0].contentString !== undefined && `: ${item.content[0].contentString}`} </p>

                                                    <div className={classes.hiddenBox} style={open === `box-${index}` ? { height: "auto", opacity: "1", zIndex: "999", maxHeight: "4000px", paddingTop: "2vh" } : { maxHeight: "0", zIndex: "-1", opacity: "0" }}>
                                                        {item.elementType !== "Line" && item.elementType !== "Break" && item.content.map((object, objectIndex) => (
                                                            <textarea
                                                                className={classes.titleInput4}
                                                                type="text"
                                                                placeholder="Text..."
                                                                value={object.contentString || ""}
                                                                onChange={(e) => handleEditCurrentContentString(index, objectIndex, e)}
                                                            />
                                                        ))}

                                                    </div>



                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>


                    <button className={classes.formButton2} onClick={handleCreateMenu}>Create Menu</button>


                </div>)}

            <h2>CURRENT MENUS</h2>

            <p className={classes.toggleP}>Change MENU Order</p>
            <p style={{fontWeight: "bold", width: "60%", color: "black", textAlign: "center"}}>You must SAVE your changes for them to take effect on the website.</p>
           {!mode && <button className={classes.toggleButtonEnter} onClick={() => { enterExitToggleMode() }}>Enter Toggle Mode</button>}
           {mode && <button className={classes.toggleButtonExit} onClick={() => { enterExitToggleMode() }}>Exit Toggle Mode</button>}
            {mode && <button className={classes.toggleButtonSave} onClick={handleSubmitOrderToggle}>SAVE NEW ORDER</button>}

            <div className={classes.menus}>


                {
                    DBMenus.map((object, index) => (

                        <div className={classes.liveMenu} key={index}>


                            <div className={classes.topDiv}>

                                {mode && index > 0 && <div className={classes.topDivArrow} onClick={() => moveUp(object._id)}><HiArrowSmUp className={classes.arrowIcon} /></div>}

                                <h3>{object.title}</h3>

                                {mode && index < DBMenus.length - 1 && <div className={classes.topDivArrow} onClick={() => moveDown(object._id)}><HiOutlineArrowSmDown className={classes.arrowIcon}/>
                                </div>}



                            </div>

                            <div className={classes.btnDiv}>
                                <button className={classes.uniButton} onClick={() => {
                                    setShowForm2(true);
                                    handleSelectedMenu(object._id)
                                }}>Edit</button>
                                <button className={classes.uniButton} onClick={() => {
                                    setShowForm(true);
                                    handleSelectedMenu(object._id)
                                }}>Delete</button>

                            </div>


                        </div>

                    ))
                }





            </div>

            <div ref={editMenuRef} className={classes.exteriorWrapper}>
                {Object.keys(selectedMenu).length !== 0 && (
                    <AdminMenuEdit property={selectedMenu} closeModal={handleCloseForm} fetchFunction={fetchDBMenus} currentUser={currentUser} />
                )}
                {Object.keys(selectedMenu).length !== 0 && (<button onClick={() => { setSelectedMenu({}) }} className={classes.uniButton2}>Clear Menu Edit</button>)}
            </div>



            <Modal
                isOpen={showForm}
                onRequestClose={() => setShowForm(false)}
                contentLabel="Delete Menu Modal"
                className="modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: "999999999999999999999999999999999999",
                    },
                    content: {
                        width: 'auto',
                        height: 'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        border: "0",
                        borderRadius: "20px",

                        overflow: "hidden",
                        WebkitOverflowScrolling: "touch", // For iOS Safari
                        scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                        scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                    },
                }}
            >
                <div className={classes.makeWrapper}>
                    <AiOutlineClose onClick={() => {
                        setShowForm(false)
                    }} className={classes.removeIcon} />
                    <h2>Are you sure you want to delete this Menu?</h2>

                    <div className={classes.btnDels}>
                        <button className={classes.uniButton} onClick={confirmDelete}>Delete</button>
                        <button className={classes.uniButton} onClick={() => setShowForm(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>




            <Modal
                isOpen={showFormPreview}
                onRequestClose={() => setShowFormPreview(false)}
                contentLabel="Preview Menu Modal"
                className="modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: "999999999999999999999999999999999999",
                    },
                    content: {
                        width: '90%',
                        height: 'auto',
                        maxHeight: '80vh',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        backgroundColor: "#f3c7c7",
                        border: "0",
                        borderRadius: "20px",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch", // For iOS Safari
                        scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                        scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                    },
                }}
            >
                <div className={classes.menuContainer}>
                    <AiOutlineClose onClick={() => {
                        setShowFormPreview(false)
                    }} className={classes.removeIcon} />








                    <p className={classes.specialP}>{currentTitle}</p>


                    {menuItems.map((object, index) => (


                        <>
                            {object.elementType === "Title" && (
                                <div className={classes.specialP}>{object.content[0].contentString}</div>


                            )}



                            {object.elementType === "Dish" && (
                                <div className={classes.dish}>
                                    <p className={classes.specialTitle}> {object.content[0].contentString}</p>
                                    <p>{object.content[1].contentString}</p>
                                    <span>{object.content[2].contentString}</span>
                                </div>

                            )}


                            {object.elementType === "WineItem" && (
                                <div className={classes.dish}>
                                    <p className={classes.specialTitle}> {object.content[0].contentString}</p>
                                    <p>{object.content[1].contentString}</p>
                                    <span>{object.content[2].contentString}</span>
                                </div>

                            )}


                            {object.elementType === "Line" && (
                                <div className={classes.hr}>
                                    <hr />


                                </div>

                            )}


                            {object.elementType === "Break" && (
                                <img className={classes.seperator} src={PF + "line.png"} alt="paragraph seperator" />

                            )}


                            {object.elementType === "CategoryTitle" && (
                                <p className={classes.categoryTitle}>{object.content[0].contentString}</p>

                            )}


                            {object.elementType === "Message" && (
                                <p className={classes.message}>{object.content[0].contentString}</p>


                            )}
                        </>




                    ))}









                </div>
            </Modal>



        </div>
    )
}

export default AdminMenus