import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import classes from "./adminRoutes.module.css"
import { request } from '../../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";
import AdminRouteEdit from '../AdminRouteEdit/AdminRouteEdit';
import ClipLoader from "react-spinners/ClipLoader";


const AdminRoutes = () => {

    const [currentTitle, setCurrentTitle] = useState("")
    const [currentDesc, setCurrentDesc] = useState("")
    const [currentDescTitle, setCurrentDescTitle] = useState("")
    const [currentRoute, setCurrentRoute] = useState("")
    const [panelTitle, setPanelTitle] = useState("")
    const [panelDesc, setPanelDesc] = useState("")
    const [panelPhoto, setPanelPhoto] = useState("")
    const [topPhoto, setTopPhoto] = useState("")
    const [currentPanelDesc, setCurrentPanelDesc] = useState("")
    const [currentPanelTitle, setCurrentPanelTitle] = useState("")
    const [currentButtonType, setCurrentButtonType] = useState("")
    const [routes, setRoutes] = useState([]);
    const [panels, setPanels] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [showForm2, setShowForm2] = useState(false)
    const [showFormPreview, setShowFormPreview] = useState(false)

    const { user, token } = useSelector((state) => state.auth)


    const handleTitle = (e) => {
        setCurrentTitle(e.target.value)
    }

    const handleDesc = (e) => {
        setCurrentDesc(e.target.value)
    }

    const handleDescTitle = (e) => {
        setCurrentDescTitle(e.target.value)
    }


    const fetchRoutes = async () => {
        const data = await request(`/routes/getAll`, 'GET');
        setRoutes(data);
        setSelectedRoute({})
        console.log(data);

    };


    useEffect(() => {
        fetchRoutes();

    }, [])


    const handleRoute = (e) => {
        const newRoute = e.target.value;
        const containsNumbers = /\d/.test(newRoute); // Regular expression to check for numbers
        const containsNonAlphabetic = /[^a-zA-Z]/.test(newRoute); // Regular expression to check for non-alphabetic characters

        if ((!newRoute.includes(" ") && newRoute.length <= 11 && !containsNumbers && !containsNonAlphabetic) || newRoute === "") {
            // Check if the input meets the conditions
            setCurrentRoute(newRoute);
        } else {
            // Handle the case where the input does not meet the conditions
            if (newRoute.includes(" ")) {
                alert("Route cannot contain space characters");
            }
            if (newRoute.length > 11) {
                alert("Route cannot exceed 11 characters");
            }
            if (containsNumbers) {
                alert("Route cannot contain numbers");
            }
            if (containsNonAlphabetic) {
                alert("Route can only contain alphabetic characters");
            }
            // Optionally clear the route state
            // setCurrentRoute("");
        }
    }



    const handlePanelTitle = (e) => {
        setCurrentPanelTitle(e.target.value)
    }

    const handlePanelDesc = (e) => {
        setCurrentPanelDesc(e.target.value)
    }

    const handleButtonTypeChange = (e) => {
        setCurrentButtonType(e.target.value);

    };


    const handleAddItem = () => {


        let newItem = {}


        if (panelPhoto === "") {
            alert("You must set a PANEL PHOTO")
            return;
        }

        if (currentButtonType !== "") {



            newItem = {

                panelImg: panelPhoto,


                panelTitle: currentPanelTitle,
                panelDesc: currentPanelDesc,
                panelButtonType: currentButtonType

            }




            setPanels([...panels, newItem]);

            setCurrentPanelTitle("");
            setCurrentPanelDesc("");
            setCurrentButtonType("")
            setPanelPhoto("")

        } else {
            alert("You must set an BUTTON type")
        }


    }


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(panels);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPanels(updatedItems);
    };


    const deletePanelItem = (index) => {
        const items = [...panels];
        items.splice(index, 1);
        setPanels(items);
    };







    const handleCreateRoute = async (e) => {




        if (currentDesc === "") {
            alert("You must provide a DESCRIPTION for the Route ...")
            return;
        }

        if (currentTitle === "") {
            alert("You must provide a TITLE for the Route...")
            return;

        }


        if (currentDescTitle === "") {
            alert("You must provide a DESCRIPTION TITLE for the Route...")
            return;

        }


        if (topPhoto === "") {
            alert("You must provide a COVER PHOTO for the Route...")
            return;
        }



        if (currentRoute === "") {
            alert("You must provide a COVER PHOTO for the Route...")
            return;
        }

        setPreloader(true)


        let filename1 = ""
        let processedPhotos = [];
        let panelPhotos = [];

        let currentPanels = panels;

        console.log(currentPanels)



        if (topPhoto) {
            const formData = new FormData();

            filename1 = crypto.randomUUID() + topPhoto.name;
            formData.append("filename", filename1);
            formData.append("image", topPhoto);

            await request('/upload/image', "POST", {}, formData, true);
        }



        for (const panel of currentPanels) {





            panelPhotos.push(panel.panelImg)


        }


        console.log(panelPhotos);



        if (panelPhotos.length > 0) {
            const uploadPromises2 = panelPhotos.map(async (photo, index) => {
                if (photo) {
                    const formData = new FormData();
                    const filename = crypto.randomUUID() + photo.name;

                    processedPhotos.push(filename);
                    formData.append("filename", filename);
                    formData.append("image", photo);

                    await request("/upload/image", "POST", {}, formData, true);
                }
            });

            try {
                await Promise.all(uploadPromises2);
            } catch (error) {
                console.error(error);
            }
        }


        let i = 0;

        console.log(processedPhotos);


        for (const panel of currentPanels) {



            panel.panelImg = processedPhotos[i]

            i++;
        }







        try {


            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;

            data = await request(`/routes`, 'POST', options, {

                title: currentTitle,

                topImg: filename1,
                route: currentRoute,

                introDesc: currentDesc,

                introTitle: currentDescTitle,

                panels: currentPanels,



            })






            setPreloader(false)
            alert("The ROUTE has been added successfully!")
            setCurrentTitle("")
            setCurrentDesc("")
            setTopPhoto("")
            setCurrentRoute("")
            setCurrentDescTitle("")
            fetchRoutes();
            setPanels([]);



        } catch (error) {
            console.error(error)
            setPreloader(false)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }


    const [selectedRoute, setSelectedRoute] = useState({})
    const editRouteRef = useRef(null);



    const handleSelectedRoute = async (id) => {
        const data = await request(`/routes/find/${id}`, "GET");
        setSelectedRoute(data);
        console.log(data)


    }

    useEffect(() => {
        if (editRouteRef.current && Object.keys(selectedRoute).length !== 0) {
            editRouteRef.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, [selectedRoute])


    const handleCloseForm = () => {


        setSelectedRoute({})
    }





    const deleteRoute = async () => {
        try {
            await request(`/routes/${selectedRoute._id}/`, 'DELETE', { Authorization: `Bearer ${token}` });


            fetchRoutes();
            setSelectedRoute({})
            alert("Your ROUTE has been successfully deleted!")

        } catch (error) {
            console.log(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };

    const confirmDelete = () => {

        deleteRoute();


        setShowForm(false);


    };



    const handleEditPanelTitle = (index, event) => {
        const newPanels = [...panels];
        newPanels[index].panelTitle = event.target.value;
        setPanels(newPanels);
    };

    const handleCurrentPanelDesc = (index, event) => {
        const newPanels = [...panels];
        newPanels[index].panelDesc = event.target.value;
        setPanels(newPanels);
    };

    const editPanelItemPhoto = (file, index) => {
        const newPanels = [...panels];
        newPanels[index].panelImg = file;
        setPanels(newPanels);
    };

    const handleCurrentButtonChange = (index, event) => {
        const newPanels = [...panels];
        newPanels[index].panelButtonType = event.target.value;
        setPanels(newPanels);
    };



    const [open, setOpen] = useState("")



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }



    const [preloader, setPreloader] = useState(false)


    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "red",


    };



    return (

        <div className={classes.container}>

            {preloader ? (
                <div style={{ width: "auto" }}>
                    <ClipLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (

                <div className={classes.makeWrapper}>
                    <h2>CREATE NEW ROUTE</h2>

                    <button className={classes.uniButton} onClick={() => { setShowFormPreview(true) }}>See Preview</button>

                    <input
                        className={classes.titleInput2}
                        type="text"
                        placeholder="Route Title..."
                        name="title"
                        value={currentTitle || ""}
                        onChange={handleTitle}
                    />

                    <input
                        className={classes.titleInput2}
                        type="text"
                        placeholder="Description Title..."
                        name="descTitle"
                        value={currentDescTitle || ""}
                        onChange={handleDescTitle}
                    />

                    <input
                        className={classes.titleInput2}
                        type="text"
                        placeholder="URL endpoint..."
                        name="route"
                        value={currentRoute || ""}
                        onChange={handleRoute}
                    />


                    <textarea
                        className={classes.titleInput4}
                        placeholder="Intro Description"
                        name="desc"
                        value={currentDesc || ""}
                        onChange={handleDesc}
                    />

                    <div className={classes.newUpload} >

                        <label className={classes.uniButton} htmlFor="topPhoto">Main Image </label>
                        <input
                            id="topPhoto"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => setTopPhoto(e.target.files[0])}
                        />


                        {topPhoto && <img src={URL.createObjectURL(topPhoto)} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} id="imgCreate" alt="Uploaded" />}







                    </div>

                    <div className={classes.keywordDiv}>

                        <h2>ADD PANEL</h2>


                        <input
                            className={classes.titleInput2}
                            type="text"
                            placeholder="Panel Title..."
                            name="title"
                            value={currentPanelTitle || ""}
                            onChange={handlePanelTitle}
                        />



                        <textarea
                            className={classes.titleInput4}
                            type="text"
                            placeholder="Panel Desc..."
                            name="title"
                            value={currentPanelDesc || ""}
                            onChange={handlePanelDesc}
                        />


                        <div className={classes.newUpload} >

                            <label className={classes.uniButton} htmlFor="panelPhoto">Add Image </label>
                            <input
                                id="panelPhoto"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => setPanelPhoto(e.target.files[0])}
                            />


                            {panelPhoto && <img src={URL.createObjectURL(panelPhoto)} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} id="imgCreate" alt="Uploaded" />}







                        </div>



                        <div className={classes.typeSelection}>
                            <label>
                                Select Button Type:
                                <select className={classes.titleInput2} value={currentButtonType} onChange={handleButtonTypeChange}>
                                    <option value="">Select an option</option> {/* Non-option */}
                                    <option value="Menu">Menu</option>
                                    <option value="Gallery">Gallery</option>
                                    <option value="ContactUs">Contact Us</option>
                                    <option value="Book">Book A Table</option>
                                    <option value="Experience">Chef's Table Experience</option>
                                    <option value="Careers">Careers</option>
                                    <option value="Music">Music</option>
                                    <option value="Wine">Wine Experience</option>
                                </select>
                            </label>
                        </div>









                        <div className={classes.plusButtonDiv}>
                            <IoMdAddCircle onClick={handleAddItem} className={classes.plusButton} />

                        </div>
                    </div>


                    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => { setOpen("") }}>
                        <Droppable droppableId="panelItems">
                            {(provided) => (
                                <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>

                                    {panels.map((item, index) => (


                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                            {(provided) => (

                                                <div className={classes.keyword}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>

                                                    <AiOutlineClose onClick={() => {
                                                        deletePanelItem(index)
                                                    }} className={classes.removeIcon} />


                                                    <p className={classes.open} onClick={() => { handleOpen(`box-${index}`) }}>{item.elementType}: {item.panelTitle}</p>

                                                    <div className={classes.hiddenBox} style={open === `box-${index}` ? { height: "auto", opacity: "1", zIndex: "999", maxHeight: "4000px", paddingTop: "2vh" } : { maxHeight: "0", zIndex: "-1", opacity: "0" }}>




                                                        <input
                                                            className={classes.titleInput2}
                                                            type="text"
                                                            placeholder="Panel Title..."
                                                            value={item.panelTitle || ""}
                                                            onChange={(e) => handleEditPanelTitle(index, e)}
                                                        />
                                                        <textarea
                                                            className={classes.titleInput4}
                                                            type="text"
                                                            placeholder="Panel Desc..."
                                                            value={item.panelDesc || ""}
                                                            onChange={(e) => handleCurrentPanelDesc(index, e)}
                                                        />
                                                        <div className={classes.newUpload}>
                                                            <label className={classes.uniButton} htmlFor={`panelPhoto-${index}`}>Add Image</label>
                                                            <input
                                                                id={`panelPhoto-${index}`}
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => editPanelItemPhoto(e.target.files[0], index)}
                                                            />
                                                            {item.panelImg && <img src={URL.createObjectURL(item.panelImg)} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} alt="Uploaded" />}
                                                        </div>
                                                        <div className={classes.typeSelection}>
                                                            <label>
                                                                Select Button Type:
                                                                <select className={classes.titleInput2} value={item.panelButtonType || ""} onChange={(e) => handleCurrentButtonChange(index, e)}>
                                                                    <option value="">Select an option</option>
                                                                    <option value="Menu">Menu</option>
                                                                    <option value="Gallery">Gallery</option>
                                                                    <option value="ContactUs">Contact Us</option>
                                                                    <option value="Book">Book A Table</option>
                                                                    <option value="Experience">Chef's Table Experience</option>
                                                                    <option value="Careers">Careers</option>
                                                                    <option value="Music">Music</option>
                                                                    <option value="Wine">Wine Experience</option>
                                                                </select>
                                                            </label>
                                                        </div>

                                                    </div>






                                                </div>

                                            )}



                                        </Draggable>

                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <button className={classes.formButton2} onClick={handleCreateRoute}>Create Route</button>


                </div>)}

            <h2>CURRENT ROUTES</h2>
            <div className={classes.menus}>


                {routes.length > 0 && routes.map((object, index) => (

                    <div className={classes.liveMenu} key={index}>
                        <h3>{object.title}</h3>

                        <div className={classes.btnDiv}>
                            <button className={classes.uniButton} onClick={() => {
                                setShowForm2(true);
                                handleSelectedRoute(object._id)
                            }}>EDIT</button>
                            <button className={classes.uniButton} onClick={() => {
                                setShowForm(true);
                                handleSelectedRoute(object._id)
                            }}>DELETE</button>

                        </div>


                    </div>

                ))
                }





            </div>


            <div ref={editRouteRef} className={classes.exteriorWrapper} >
                {Object.keys(selectedRoute).length !== 0 && (
                    <AdminRouteEdit property={selectedRoute} closeModal={handleCloseForm} fetchFunction={fetchRoutes} />
                )}
                {Object.keys(selectedRoute).length !== 0 && (<button onClick={() => { setSelectedRoute({}) }} className={classes.uniButton2}>Clear Route Edit</button>)}
            </div>


            <Modal
                isOpen={showForm}
                onRequestClose={() => setShowForm(false)}
                contentLabel="Delete User Modal"
                className="modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: "999999999999999999999999999999999999",
                    },
                    content: {
                        width: 'auto',
                        height: 'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        border: "0",
                        borderRadius: "20px",

                        overflow: "hidden",
                        WebkitOverflowScrolling: "touch", // For iOS Safari
                        scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                        scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                    },
                }}
            >
                <div className={classes.makeWrapper}>
                    <AiOutlineClose onClick={() => {
                        setShowForm(false)
                    }} className={classes.removeIcon} />
                    <h2>Are you sure you want to delete this Route?</h2>

                    <div className={classes.btnDels}>
                        <button className={classes.uniButton} onClick={confirmDelete}>Delete</button>
                        <button className={classes.uniButton} onClick={() => setShowForm(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showFormPreview}
                onRequestClose={() => setShowFormPreview(false)}
                contentLabel="Preview Menu Modal"
                className="modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: "999999999999999999999999999999999999",
                    },
                    content: {
                        width: '90%',
                        height: 'auto',
                        maxHeight: '80vh',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        backgroundColor: "#f3c7c7",
                        border: "0",
                        borderRadius: "20px",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch", // For iOS Safari
                        scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                        scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                    },
                }}
            >
                <div className={classes.modalContainer}>
                    <AiOutlineClose onClick={() => {
                        setShowFormPreview(false)
                    }} className={classes.removeIcon} />




                    <div className={classes.topDiv}>
                        <div className={classes.abso}> <span>{currentTitle}</span></div>
                        <img src={topPhoto instanceof Blob ? URL.createObjectURL(topPhoto) : `https://connollysapi-n476.onrender.com/images/${topPhoto}`} alt="This photo panel is currently empty" />

                    </div>
                    <div className={classes.middleDiv}>
                        <p className={classes.specialP}>{currentDescTitle}</p>
                        <div className={classes.hr}>
                            <hr />
                            <hr />

                        </div>
                        <p className={classes.desc} id={classes.descId}>
                            {currentDesc}
                        </p>

                    </div>






                    {panels.map((panel, index) => (


                        <>

                            {index % 2 === 0 && (

                                <div className={classes.row}>
                                    <div className={classes.imgCont}>
                                        <img alt="festive" src={panel.panelImg instanceof Blob ? URL.createObjectURL(panel.panelImg) : `https://connollysapi-n476.onrender.com/images/${panel.panelImg}`} />
                                    </div>
                                    <div className={classes.txtBox} >
                                        <div className={classes.hr}>
                                            <hr />
                                        </div>
                                        <p className={classes.specialP}>{panel.panelTitle}</p>
                                        <p className={classes.desc}>
                                            {panel.panelDesc}
                                        </p>





                                        {panel.panelButtonType === "Menu" && (
                                            <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>SEE MENUS</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Gallery" && (

                                            <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "ContactUs" && (

                                            <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>CONTACT US</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Book" && (

                                            <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Experience" && (
                                            <a className={classes.a} href="/chefs-table-experience"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )

                                        }

                                        {panel.panelButtonType === "Careers" && (
                                            <a className={classes.a} href="/careers"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Music" && (
                                            <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Wine" && (
                                            <a className={classes.a} href="/wine-experience-room"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>


                                        )

                                        }




                                        <div className={classes.hr}>
                                            <hr />
                                        </div>
                                    </div>
                                </div>




                            )


                            }


                            {

                                index % 2 !== 0 && (


                                    <div className={classes.row}>
                                        <div className={classes.imgCont} id={classes.idOne} >


                                            <img src={panel.panelImg instanceof Blob ? URL.createObjectURL(panel.panelImg) : `https://connollysapi-n476.onrender.com/images/${panel.panelImg}`} />
                                        </div>
                                        <div className={classes.txtBox} id={classes.idTwo} >
                                            <div className={classes.hr}>
                                                <hr />
                                            </div>
                                            <p className={classes.specialP}>{panel.panelTitle}</p>
                                            <p className={classes.desc}>
                                                {panel.panelDesc}
                                            </p>



                                            {panel.panelButtonType === "Menu" && (
                                                <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>SEE MENUS</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Gallery" && (

                                                <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "ContactUs" && (

                                                <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>CONTACT US</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Book" && (

                                                <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Experience" && (
                                                <a className={classes.a} href="/chefs-table-experience"> <button className={classes.giftBtn}>CHEF'S TABLE</button></a>

                                            )

                                            }

                                            {panel.panelButtonType === "Careers" && (
                                                <a className={classes.a} href="/careers"> <button className={classes.giftBtn}>CAREERS</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Music" && (
                                                <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>WHAT'S ON?</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Wine" && (
                                                <a className={classes.a} href="/wine-experience-room"> <button className={classes.giftBtn}>WINE CAVE</button></a>


                                            )

                                            }




                                            <div className={classes.hr}>
                                                <hr />
                                            </div>

                                        </div>
                                    </div>




                                )


                            }





                        </>




                    ))}









                </div>
            </Modal>




        </div>

    )
}

export default AdminRoutes