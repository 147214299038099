import React, { useEffect, useState } from 'react'
import classes from "./adminRouteEdit.module.css"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-modal';


const AdminRouteEdit = ({ property, closeModal, fetchFunction }) => {

    const { token, user } = useSelector((state) => state.auth)
    const { id } = useParams()
    const navigate = useNavigate()



    const [panelItems, setPanelItems] = useState([])
    const [newPanelItems, setNewPanelItems] = useState([])

    const [currentPanelTitle, setCurrentPanelTitle] = useState("")
    const [currentPanelDesc, setCurrentPanelDesc] = useState("")
    const [currentButtonType, setCurrentButtonType] = useState("")
    const [currentTitle, setCurrentTitle] = useState("")
    const [currentDesc, setCurrentDesc] = useState("")
    const [currentDescTitle, setCurrentDescTitle] = useState("")
    const [currentRoute, setCurrentRoute] = useState("")
    const [showFormPreview, setShowFormPreview] = useState(false)

    const [panelPhoto, setPanelPhoto] = useState("")



    const [topPhoto, setTopPhoto] = useState("")




    useEffect(() => {

        console.log("Running")

        setCurrentTitle(property.title)
        setCurrentRoute(property.route)
        setCurrentDesc(property.introDesc)
        setCurrentDescTitle(property.introTitle)
      


        setPanelItems(property.panels)



    }, [property]);
















    const handleEditRoute = async (e) => {

        setPreloader(true)


        let filename1 = ""





        if (topPhoto) {
            const formData = new FormData()




            filename1 = crypto.randomUUID() + topPhoto.name
            formData.append("filename", filename1)
            formData.append("image", topPhoto)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename1 = property.topImg
        }









        let oldPanels = [...panelItems]; // Create a copy of panelItems

        // Create arrays to hold the original indices and the panels
        let editOldPanels = [];
        let editOldPanelsIndices = [];
        let remainingOldPanels = [];
        let remainingOldPanelsIndices = [];

        // Separate panels into edited and remaining, while keeping track of their original indices
        oldPanels.forEach((panel, index) => {
            if (panel.isPhotoEdited) {
                editOldPanels.push(panel);
                editOldPanelsIndices.push(index);
            } else {
                remainingOldPanels.push(panel);
                remainingOldPanelsIndices.push(index);
            }
        });

        let processedPhotos2 = [];

        if (editOldPanels.length > 0) {
            const uploadPromises = editOldPanels.map(async (panel, index) => {
                if (panel.panelImg) {
                    const formData = new FormData();
                    const filename = crypto.randomUUID() + panel.panelImg.name;

                    processedPhotos2.push({ index, filename });  // Track the filename and the panel index
                    formData.append("filename", filename);
                    formData.append("image", panel.panelImg);

                    await request("/upload/image", "POST", {}, formData, true);
                }
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update panels with new photo filenames
            for (const { index, filename } of processedPhotos2) {
                editOldPanels[index].panelImg = filename;  // Update the panelImg field with the new filename
            }
        }

        // Combine the edited and remaining panels back into the original order
        let combinedPanels = new Array(oldPanels.length);

        // Place edited panels back into their original positions
        editOldPanels.forEach((panel, i) => {
            combinedPanels[editOldPanelsIndices[i]] = panel;
        });

        // Place remaining panels back into their original positions
        remainingOldPanels.forEach((panel, i) => {
            combinedPanels[remainingOldPanelsIndices[i]] = panel;
        });


        if (currentRoute !== "") {

        }




        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;


            data = await request(`/routes/${property?._id}`, 'PUT', options, {
                title: currentTitle,
                route: currentRoute,
                topImg: filename1,
                introDesc: currentDesc,
                introTitle: currentDescTitle,
                panels: [...combinedPanels,]

            })

            fetchFunction();

            setPanelItems([])
            setNewPanelItems([])



            setPreloader(false)

            alert("Your ROUTE has been successfully updated!")
            closeModal();

        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");
            setPreloader(false)
        }





    }





    const deletePanelItem = (index) => {
        const items = [...panelItems];
        items.splice(index, 1);
        setPanelItems(items);
    };


    const deleteNewPanelItem = (index) => {
        const items = [...newPanelItems];
        items.splice(index, 1);
        setNewPanelItems(items);
    };



    const handleButtonTypeChange = (e) => {
        setCurrentButtonType(e.target.value);

    };


    const handleTitle = (e) => {
        setCurrentTitle(e.target.value)
    }

    const handleDesc = (e) => {
        setCurrentDesc(e.target.value)
    }

    const handleDescTitle = (e) => {
        setCurrentDescTitle(e.target.value)
    }


    const handleRoute = (e) => {
        const newRoute = e.target.value;
        console.log(newRoute);
        const containsNumbers = /\d/.test(newRoute); // Regular expression to check for numbers
        const containsNonAlphabetic = /[^a-zA-Z]/.test(newRoute); // Regular expression to check for non-alphabetic characters

        if ((!newRoute.includes(" ") && newRoute.length <= 11 && newRoute !== "" && !containsNumbers && !containsNonAlphabetic) || newRoute === "") {
            // Check if the input meets the conditions
            setCurrentRoute(newRoute);
        } else {
            // Handle the case where the input does not meet the conditions
            if (newRoute.includes(" ")) {
                alert("Route cannot contain space characters");
            }
            if (newRoute.length > 11) {
                alert("Route cannot exceed 11 characters");
            }
      
            if (containsNumbers) {
                alert("Route cannot contain numbers");
            }
            if (containsNonAlphabetic) {
                alert("Route can only contain alphabetic characters");
            }
            // Optionally clear the route state
            // setCurrentEditRoute("");
        }
    }








    const handlePanelTitle = (e) => {
        setCurrentPanelTitle(e.target.value)
    }

    const handlePanelDesc = (e) => {
        setCurrentPanelDesc(e.target.value)
    }








    const handleAddItem = () => {


        let newItem = {}

        if (currentButtonType !== "") {



            newItem = {

                panelImg: panelPhoto,


                panelTitle: currentPanelTitle,
                panelDesc: currentPanelDesc,
                panelButtonType: currentButtonType,
                isPhotoEdited: true

            }




            setPanelItems([...panelItems, newItem]);


            setCurrentPanelTitle("");
            setCurrentPanelDesc("");
        
          
            setPanelPhoto("");
            setCurrentButtonType("");

        } else {
            alert("You must set an BUTTON type")
        }


    }



    const handleEditPanelTitle = (index, event) => {
        const newPanels = [...panelItems];
        newPanels[index].panelTitle = event.target.value;
        setPanelItems(newPanels);
    };

    const handleCurrentPanelDesc = (index, event) => {
        const newPanels = [...panelItems];
        newPanels[index].panelDesc = event.target.value;
        setPanelItems(newPanels);
    };

    const handlePhotoEdit = (file, index) => {
        const newPanels = [...panelItems];
        newPanels[index].panelImg = file;
        newPanels[index].isPhotoEdited = true;  // Flag to indicate the photo was edited
        setPanelItems(newPanels);
    };

    const handleCurrentButtonChange = (index, event) => {
        const newPanels = [...panelItems];
        newPanels[index].panelButtonType = event.target.value;
        setPanelItems(newPanels);
    };


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(panelItems);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPanelItems(updatedItems);
    };






    const [open, setOpen] = useState("")



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }



    const [preloader, setPreloader] = useState(false)


    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "red",


    };










    return (


        <>
            {preloader ? (
                <div style={{ width: "auto" }}>
                    <ClipLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (



                <div className={classes.makeWrapper}>

                    <h2>Edit Route</h2>


                    <button className={classes.uniButton} onClick={() => { setShowFormPreview(true) }}>See Preview</button>


                    <label className={classes.classicLabel}>

                        Title
                        <input
                            className={classes.titleInput2}
                            type="text"
                            placeholder="Route Title..."
                            name="title"
                            value={currentTitle || ""}
                            onChange={handleTitle}
                        />

                    </label>

                    <label className={classes.classicLabel}>

                        Description Title
                        <input
                            className={classes.titleInput2}
                            type="text"
                            placeholder="Description Title..."
                            name="descTitle"
                            value={currentDescTitle || ""}
                            onChange={handleDescTitle}
                        />
                    </label>


                    <label className={classes.classicLabel}>

                        URL endpoint

                        <input
                            className={classes.titleInput2}
                            type="text"
                            placeholder="URL endpoint..."
                            name="route"
                            value={currentRoute || ""}
                            onChange={handleRoute}
                        />

                    </label>


                    <textarea
                        className={classes.titleInput4}
                        placeholder="Intro Description"
                        name="desc"
                        value={currentDesc || ""}
                        onChange={handleDesc}
                    />

                    <div className={classes.newUpload} >
                        <label className={classes.uniButton} htmlFor="topPhotoEdit">Main Image</label>
                        <input
                            id="topPhotoEdit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setTopPhoto(e.target.files[0]) }}
                        />

                        {topPhoto === "" ? (


                            <img src={`https://connollysapi-n476.onrender.com/images/${property?.topImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                        ) : (
                            <img src={URL.createObjectURL(topPhoto)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                        )}
                    </div>

                    <div className={classes.keywordDiv}>

                        <h2>ADD PANEL</h2>


                        <input
                            className={classes.titleInput2}
                            type="text"
                            placeholder="Panel Title..."
                            name="title"
                            value={currentPanelTitle || ""}
                            onChange={handlePanelTitle}
                        />



                        <textarea
                            className={classes.titleInput4}
                            type="text"
                            placeholder="Panel Desc..."
                            name="paneldesc"
                            value={currentPanelDesc || ""}
                            onChange={handlePanelDesc}
                        />



                        <div className={classes.newUpload} >

                            <label className={classes.uniButton} htmlFor="panelPhotoEdit">Add Image </label>
                            <input
                                id="panelPhotoEdit"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => setPanelPhoto(e.target.files[0])}
                            />


                            {panelPhoto && <img src={URL.createObjectURL(panelPhoto)} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} id="imgCreate" alt="Uploaded" />}







                        </div>



                        <div className={classes.typeSelection}>
                            <label>
                                Select Button Type:
                                <select className={classes.titleInput2} value={currentButtonType} onChange={handleButtonTypeChange}>
                                    <option value="">Select an option</option> {/* Non-option */}
                                    <option value="Menu">Menu</option>
                                    <option value="Gallery">Gallery</option>
                                    <option value="ContactUs">Contact Us</option>
                                    <option value="Book">Book A Table</option>
                                    <option value="Experience">Chef's Table Experience</option>
                                    <option value="Careers">Careers</option>
                                    <option value="Music">Music</option>
                                    <option value="Wine">Wine Experience</option>
                                </select>
                            </label>
                        </div>









                        <div className={classes.plusButtonDiv}>
                            <IoMdAddCircle onClick={handleAddItem} className={classes.plusButton} />

                        </div>
                    </div>




                    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => { setOpen("") }}>
                        <Droppable droppableId="panelItems">
                            {(provided) => (
                                <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                    {panelItems?.map((item, index) => (


                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                            {(provided) => (


                                                <div

                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}

                                                    className={classes.keyword}
                                                >

                                                    <AiOutlineClose onClick={() => {
                                                        deletePanelItem(index)
                                                    }} className={classes.removeIcon} />


                                                    <p className={classes.open} onClick={() => { handleOpen(`box-${index}`) }}>{item.panelTitle}</p>

                                                    <div className={classes.hiddenBox} style={open === `box-${index}` ? { height: "auto", opacity: "1", zIndex: "999", maxHeight: "4000px", paddingTop: "2vh" } : { maxHeight: "0", zIndex: "-1", opacity: "0" }}>






                                                        <input
                                                            className={classes.titleInput2}
                                                            type="text"
                                                            placeholder="Panel Title..."
                                                            value={item.panelTitle || ""}
                                                            onChange={(e) => handleEditPanelTitle(index, e)}
                                                        />
                                                        <textarea
                                                            className={classes.titleInput4}
                                                            type="text"
                                                            placeholder="Panel Desc..."
                                                            value={item.panelDesc || ""}
                                                            onChange={(e) => handleCurrentPanelDesc(index, e)}
                                                        />
                                                        <div className={classes.newUpload}>
                                                            <label className={classes.uniButton} htmlFor={`panelPhoto-${index}`}>Add Image</label>
                                                            <input
                                                                id={`panelPhoto-${index}`}
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => handlePhotoEdit(e.target.files[0], index)}
                                                            />

                                                            {panelItems[index].isPhotoEdited ? (
                                                                item.panelImg && <img src={URL.createObjectURL(item.panelImg)} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} alt="Uploaded" />

                                                            ) :


                                                                (<img src={`https://connollysapi-n476.onrender.com/images/${item.panelImg}`} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} id="imgCreate" alt="Uploaded" />)}

                                                        </div>
                                                        <div className={classes.typeSelection}>
                                                            <label>
                                                                Select Button Type:
                                                                <select className={classes.titleInput2} value={item.panelButtonType || ""} onChange={(e) => handleCurrentButtonChange(index, e)}>
                                                                    <option value="">Select an option</option>
                                                                    <option value="Menu">Menu</option>
                                                                    <option value="Gallery">Gallery</option>
                                                                    <option value="ContactUs">Contact Us</option>
                                                                    <option value="Book">Book A Table</option>
                                                                    <option value="Experience">Chef's Table Experience</option>
                                                                    <option value="Careers">Careers</option>
                                                                    <option value="Music">Music</option>
                                                                    <option value="Wine">Wine Experience</option>
                                                                </select>
                                                            </label>
                                                        </div>

                                                    </div>



                                                </div>
                                            )}



                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>






                    <button className={classes.formButton2} onClick={handleEditRoute}>Update Route</button>

                    <Modal
                isOpen={showFormPreview}
                onRequestClose={() => setShowFormPreview(false)}
                contentLabel="Preview Menu Modal"
                className="modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: "999999999999999999999999999999999999",
                    },
                    content: {
                        width: '90%',
                        height: 'auto',
                        maxHeight: '80vh',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        backgroundColor: "#f3c7c7",
                        border: "0",
                        borderRadius: "20px",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch", // For iOS Safari
                        scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                        scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                    },
                }}
            >
                <div className={classes.modalContainer}>
                    <AiOutlineClose onClick={() => {
                        setShowFormPreview(false)
                    }} className={classes.removeIcon} />




                    <div className={classes.topDiv}>
                        <div className={classes.abso}> <span>{currentTitle}</span></div>
                        <img src={topPhoto instanceof Blob ? URL.createObjectURL(topPhoto) : `https://connollysapi-n476.onrender.com/images/${property.topImg}`} alt="Photo currently empty" />

                    </div>
                    <div className={classes.middleDiv}>
                        <p className={classes.specialP}>{currentDescTitle}</p>
                        <div className={classes.hr}>
                            <hr />
                            <hr />

                        </div>
                        <p className={classes.desc} id={classes.descId}>
                            {currentDesc}
                        </p>

                    </div>






                    {panelItems.map((panel, index) => (


                        <>

                            {index % 2 === 0 && (

                                <div className={classes.row}>
                                    <div className={classes.imgCont}>
                                        <img alt="festive" src={panel.panelImg instanceof Blob ? URL.createObjectURL(panel.panelImg) : `https://connollysapi-n476.onrender.com/images/${panel.panelImg}`} />
                                    </div>
                                    <div className={classes.txtBox} >
                                        <div className={classes.hr}>
                                            <hr />
                                        </div>
                                        <p className={classes.specialP}>{panel.panelTitle}</p>
                                        <p className={classes.desc}>
                                            {panel.panelDesc}
                                        </p>





                                        {panel.panelButtonType === "Menu" && (
                                            <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>SEE MENUS</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Gallery" && (

                                            <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "ContactUs" && (

                                            <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>CONTACT US</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Book" && (

                                            <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Experience" && (
                                            <a className={classes.a} href="/chefs-table-experience"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )

                                        }

                                        {panel.panelButtonType === "Careers" && (
                                            <a className={classes.a} href="/careers"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Music" && (
                                            <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                        )


                                        }

                                        {panel.panelButtonType === "Wine" && (
                                            <a className={classes.a} href="/wine-experience-room"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>


                                        )

                                        }




                                        <div className={classes.hr}>
                                            <hr />
                                        </div>
                                    </div>
                                </div>




                            )


                            }


                            {

                                index % 2 !== 0 && (


                                    <div className={classes.row}>
                                        <div className={classes.imgCont} id={classes.idOne} >


                                            <img src={panel.panelImg instanceof Blob ? URL.createObjectURL(panel.panelImg) : `https://connollysapi-n476.onrender.com/images/${panel.panelImg}`} />
                                        </div>
                                        <div className={classes.txtBox} id={classes.idTwo} >
                                            <div className={classes.hr}>
                                                <hr />
                                            </div>
                                            <p className={classes.specialP}>{panel.panelTitle}</p>
                                            <p className={classes.desc}>
                                                {panel.panelDesc}
                                            </p>



                                            {panel.panelButtonType === "Menu" && (
                                                <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>SEE MENUS</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Gallery" && (

                                                <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "ContactUs" && (

                                                <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>CONTACT US</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Book" && (

                                                <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Experience" && (
                                                <a className={classes.a} href="/chefs-table-experience"> <button className={classes.giftBtn}>CHEF'S TABLE</button></a>

                                            )

                                            }

                                            {panel.panelButtonType === "Careers" && (
                                                <a className={classes.a} href="/careers"> <button className={classes.giftBtn}>CAREERS</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Music" && (
                                                <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>WHAT'S ON?</button></a>

                                            )


                                            }

                                            {panel.panelButtonType === "Wine" && (
                                                <a className={classes.a} href="/wine-experience-room"> <button className={classes.giftBtn}>WINE CAVE</button></a>


                                            )

                                            }




                                            <div className={classes.hr}>
                                                <hr />
                                            </div>

                                        </div>
                                    </div>




                                )


                            }





                        </>




                    ))}









                </div>
            </Modal>

                </div>


            )} </>




    )
}

export default AdminRouteEdit