import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: {

        musicGalleryImgs: []
        
       
       
       
    }
 
 }



 export const websiteSlice = createSlice({
    name: 'website',
    initialState,
    reducers: {
        getData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getData} = websiteSlice.actions;

export default websiteSlice.reducer;



