import React, {useEffect, useRef, useState} from 'react'
import classes from "./galleryFinal.module.css"
import { FaArrowRight } from "react-icons/fa";

const GalleryFinal = ({ images }) => {


    const descRef = useRef([]);
    const PF = process.env.REACT_APP_PUBLIC_FOLDER;

    const [initialState, setInitialState] = useState(false)
    
   


    useEffect(() => {

      if(images.length > 0 && initialState === true) {
        const appearOptions = {
          threshold: 0,
        };
    
        const appearOnScroll = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(classes.appear);
              observer.unobserve(entry.target);
            }
          });
        }, appearOptions);
    
        descRef.current.forEach((desc) => {
          appearOnScroll.observe(desc);
        });
      
        return () => {
          appearOnScroll.disconnect();
        };

      

    } else {
      setInitialState(true)
    }


      }, [images]);
  

  
  return (
    <div className={classes.container}>


<div className={classes.topWrapper}>
        <h2>RESTAURANT GALLERY</h2>


        <a href="/book-a-table" className={classes.link}>
          <p>BOOK A TABLE</p>


          <FaArrowRight />
        </a>

      </div>
        <div className={classes.wrapper}>
        {images.map((image, index) => (
          <div className={classes.imgCont} ref={(el) => (descRef.current[index] = el)} key={index}>
            <img src={`https://connollysapi-n476.onrender.com/images/${image}` } alt={`Image ${index}`} />
          </div>
        ))}
            
        </div>
    </div>
  )
}

export default GalleryFinal;