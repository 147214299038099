import React from 'react'
import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import ColumnHero from '../../components/columnHero/ColumnHero'
import Navbar2 from '../../components/navbar2/navbar2'
import TopComponent from '../../components/topComponent/TopComponent'
import GalleryFinal from '../../components/galleryFinal/GalleryFinal'
import { useSelector } from 'react-redux'


const GalleryPage = () => {


  const  navStyles={ backgroundColor: "#fff", position: "relative"}


  const images = useSelector((state) => state.website.data.galleryImgs);

 
  return (
    <div className="homeContainer">
   
   {/*<Title/> */}
   <TopComponent/>
    <Navbar2 styles2={navStyles}/>

    { images &&
    
    <GalleryFinal images={images}/>

}
    



<ColumnHero/>

<DisclaimerRow/>

    
    </div>
  )
}

export default GalleryPage