import React, { useEffect, useRef, useState } from 'react'
import classes from "./musicGalleryFinal.module.css"
import { FaArrowRight } from "react-icons/fa";

const MusicGalleryFinal = ({ images }) => {


  const descRef = useRef([]);
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [initialState, setInitialState] = useState(false)




  useEffect(() => {

    if (images.length > 0 && initialState === true) {
      const appearOptions = {
        threshold: 0,
      };

      const appearOnScroll = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(classes.appear);
            observer.unobserve(entry.target);
          }
        });
      }, appearOptions);

      descRef.current.forEach((desc) => {
        appearOnScroll.observe(desc);
      });

      return () => {
        appearOnScroll.disconnect();
      };

    } else {
      setInitialState(true)
    }


  }, [images]);






  return (
    <div className={classes.container}>


      <div className={classes.topWrapper}>
        <h2>MUSIC GALLERY</h2>


        <a href="/music-at-connollys" className={classes.link}>
          <p>SEE UPCOMING EVENTS</p>


          <FaArrowRight />
        </a>

      </div>


      <div className={classes.wrapper}>
        {images.map((image, index) => (
          <div className={classes.imgCont} ref={(el) => (descRef.current[index] = el)} key={index}>
            <img src={`https://connollysapi-n476.onrender.com/images/${image}`} alt={`Image ${index}`} />
          </div>
        ))}

      </div>
    </div>
  )
}

export default MusicGalleryFinal;